@import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";

.me{
    @include container;
}

.section{
    margin-top: 80px;
    & + .section{
        margin-top: 160px;
    }
    &_heading{
        font-size: $heading-too-large;
        margin-bottom: 0;
        & + p {
            margin-top: .5em;
        }
    }
    p{
        font-size: clamp(20px, 3vw, #{$heading-large});
    }
}

.inlineLink{
    color: var(--linkColor);
    text-decoration: none;
    box-shadow: inset 0 -2px var(--linkColorHover);
    &:hover{
        background-color: var(--linkColorHover);
        box-shadow: inset 0 -2px var(--linkColor);
    }
}

.image{
    max-width: 100%;
}

