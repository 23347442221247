// @import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";
.main{
    min-height: 90vh;
}

.footer{
    background-color: var(--cardColor);
    margin-top: 60px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 14px;
    &Container{
        @include wideContainer;
        padding-left: var(--sidePad);
        padding-right: var(--sidePad);
    }
}
.footerName{
    font-weight: 700;
    margin-bottom: 0;
    & + p{
        margin-top: 0.25em;
    }
}