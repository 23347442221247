@import './scssVariables.module.scss';

@mixin visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin resetFieldSet {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

@mixin container {
  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;
}
@mixin wideContainer{
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
}

@mixin motionComfort{
  @media screen and (prefers-reduced-motion: no-preference) { 
    @content;
  }
}

@mixin inlineLink {
  color: var(--linkColor);
  text-decoration: none;
  background-image: linear-gradient(
    var(--linkColorHover),
    var(--linkColorHover)
  );
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: bottom;
  transition: all 0.2s;
  &:hover {
    background-size: 100% 50%;
  }
}

@mixin removeButtonStyles($removeFocus: false) {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  @if $removeFocus == true {
    outline: none;
  }
}