@import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";

.articleText {
  padding-left: var(--sidePad);
  padding-right: var(--sidePad);
  line-height: 1.8;
  @media screen and (max-width: 1439px){
    @include container;
  }
  @media screen and (min-width: 1440px){
    display: grid;
    // grid-gap: 24px;
    grid-template-columns: 
    [full-start] minmax(1em, 1fr) 
    [main-start] minmax(0, #{$containerWidth}) [main-end]
    minmax(1em, 1fr) [full-end];  
  }
  p {
    font-size: $text-large;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    &:first-child{
      margin-top: 0;
    }
    & > code {
      background: var(--codeBGColor);
      border-radius: 4px;
      padding: 2px 4px;
      font-size: $text-large - 2;
    }
  }

  pre {
    border-radius: 0 4px 4px 4px;
    background: var(--codeBGColor) !important;
    padding: 12px !important;
    margin: 0 !important;
    span {
      text-shadow: none !important;
      font-size: $text-large - 2 !important;
      line-height: 1.8 !important;
      &:not([class]) {
        color: var(--textColor) !important;
      }
    }
    [class*="token"] {
      background: none !important;
    }
    code {
      position: relative;
      display: block;
    }
  }
  img {
    max-width: 100%;
    border-radius: 4px;
  }
  a:not([class]) {
    @include inlineLink;
  }
}
.codeBlock {
  display: block;
  position: relative;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  &:before {
    content: "code";
    display: inline-block;
    background-color: var(--codeBGColor);
    color: var(--textColor);
    text-shadow: none;
    padding: 4px 12px;
    border-radius: 4px 4px 0 0;
    font-size: 13px;
    font-weight: 700;
  }
  &-html:before {
    content: "HTML";
  }
  &-css:before {
    content: "CSS";
  }
  &-javascript:before {
    content: "JS";
  }
  &-svg:before {
    content: "SVG";
  }
}

.linkTitle {
  line-height: 1.4;
  margin-top: 2.5em;
  margin-bottom: 0.75em;
  font-size: $heading-rather-large;
  &Anchor {
    scroll-margin-top: 2em;
    background-image: linear-gradient(var(--linkColor), var(--linkColor));
    background-size: 100% 2px;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    &:before {
      content: " 🔗";
      display: none;
      position: absolute;
      top: 0.2em;
      right: calc(100% + 8px);
      flex: none;
      font-size: 0.75em;
      margin-left: 12px;
    }
    text-decoration: none;
    color: inherit;
    &:hover {
      background-size: 100% 4px;
      &:before {
        @media screen and (min-width: 1001px){
          display: block;
        }
      }
    }
  }
}

.h3 {
  line-height: 1.4;
  margin-top: 2.5em;
  margin-bottom: 0.75em;
  font-size: $heading-large;
}
.mediaRow {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  & + .mediaRow {
    margin-top: -1em;
  }
  &Item {
    // flex: 1;
    + .mediaRowItem {
    }
    img {
      width: 100%;
      display: block;
    }
  }
  &Heading{
      background-color: var(--accentShade);
      padding: 2px 4px;
      border-radius: 4px 4px 0 0;
      font-weight: 700;
      & + img{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
      }
  }
}

.jumpNav{
  margin-bottom: 2.5em;
  &Label{
    margin-right: 12px;
  }
  &Link{
    @media screen and (max-width: 1439px){
      @include inlineLink;
      margin-right: 12px;
    }
    @media screen and (min-width: 1440px){
      color: var(--linkColor);
      text-decoration: none;
      padding: 8px 0;
      line-height: 1.4;
      &:hover {
        color: var(--textColor);
      }
    }
  }
  &Container{
    @media screen and (min-width: 1440px){
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: sticky;
      top: 4em;
    }
  }
}

.content{
  @media screen and (min-width: 1440px){
    padding-left: var(--sidePad);
    padding-right: var(--sidePad);
  }
}

[data-reach-dialog-overlay] {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-reach-dialog-content] {
  margin: 0;
  width: calc(100% - var(--sidePad));
  max-height: 90vh;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 60px 0 rgba(0,0,0,0.2);
}

.lightboxImage{
  max-width: 100%;
  display: block;
}