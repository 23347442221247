@import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";

.header {
  @include container;
  margin-bottom: 40px;
  padding-left: var(--sidePad);
  padding-right: var(--sidePad);
}

.title {
  font-size: $heading-too-large;
  line-height: 1.4;
  margin-bottom: 0;
  font-weight: 900;
}

.date {
  font-size: $heading-regular;
  line-height: 1;
}