@import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";

.home{
    @include container;
}
.section{
    margin-top: 80px;
    & + .section{
        border-top: 1px solid var(--textColor);
    }
    &_heading{
        font-size: $heading-too-large;
        margin-bottom: 0;
        padding-left: 12px;
        padding-right: 12px;
        & + p {
            margin-top: .5em;
        }
    }
    
}

.largeText{
    font-size: clamp(20px, 3vw, #{$heading-large});
    padding-left: 12px;
    padding-right: 12px;
    
}

.inlineLink{
    @include inlineLink;
}

.work{
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit,minmax(240px,280px));
    
}

.articles{
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit,minmax(288px,1fr));

}