@import "./scssVariables.module.scss";
@import "./scssMixins.module.scss";

.header {
  position: sticky;
  top: 0;
  margin-bottom: 16px;
  margin-top: 24px;
  text-decoration: none;
  z-index: 1;
  @media screen and (max-width: 1000px){
    background-color: var(--backgroundColor);
  }

}
.sentinel {
  background: transparent;
  height: 1px;
  width: 1px;
  position: absolute;
  top: -2px;
  left: 0;
}
.container {
  padding-left: var(--sidePad);
  padding-right: var(--sidePad);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link{
    text-decoration: none;
    color: var(--linkColor);
    &:hover{
        color: var(--textColor);
    }
}

.logo {
  text-transform: uppercase;
  font-weight: 900;
  font-size: $heading-rather-large;
  margin: 0;
}
.minimal{
    .minimal_hide{
        display: none;
    }
}

.toggle {
  @include resetFieldSet;
  font-size: $heading-regular;
  display: flex;
  align-items: center;
  border-radius: 1.5em;
  overflow: hidden;
  background-color: var(--backgroundColor);
  &_legend {
    @include visuallyHidden;
  }
  &_radio {
    @include visuallyHidden;
    &:checked + label {
      background: var(--linkColor);
    }
    &:not(:checked) + label {
      cursor: pointer;
    }
  }
  &_label {
    background-color: var(--colorModeToggleBackground);
    min-width: 2em;
    display: inline-flex;
    justify-content: center;
    span {
      padding: 2px;
    }
  }
}
