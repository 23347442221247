@import './scssVariables.module.scss';
@import './scssMixins.module.scss';

.card{
    --shadowX: 0px;
    --shadowY: 0px;
    padding: 12px;
    color: inherit;
    border-radius: 4px;
    text-decoration: none;
    box-shadow: var(--shadowX) var(--shadowY) 0 0 transparent;
    transition: box-shadow 0.2s;
    display: block;
    &:hover{
        background-color: var(--cardColor);
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-image: radial-gradient(circle 200px at var(--x) var(--y), var(--cardColorShine), transparent);
        box-shadow: var(--shadowX) var(--shadowY) 36px 0 var(--cardShadowColor);
    }
}

.cardButton{
    --shadowX: 0px;
    --shadowY: 0px;
    @include removeButtonStyles;
    color: inherit;
    border-radius: 4px;
    text-decoration: none;
    box-shadow: var(--shadowX) var(--shadowY) 0 0 transparent;
    transition: box-shadow 0.2s;
    display: block;
    &:hover{
        background-color: var(--cardColor);
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-image: radial-gradient(circle 200px at var(--x) var(--y), var(--cardColorShine), transparent);
        box-shadow: var(--shadowX) var(--shadowY) 36px 0 var(--cardShadowColor);
    }
}


.dummyCard{
    padding: 16px;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s;
    &:before{
        position: absolute;
        display: block;
        content: "🙈";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        opacity: 0;
        filter: blur(30px);
        transition: all 0.3s;
    }
    .title, .sub, .body{
        transition: all 0.3s;
    }
    &:hover{
        background-color: var(--cardColor); 
        cursor: not-allowed;
        &:before{
            opacity: 1;
            filter: blur(0);
        }
        .title, .sub, .body{
            filter: blur(30px);
        }
    }
}
.title{
    font-size: $heading-large;
    font-weight: 700;
    .card:hover &{
        color: var(--linkColor);
    }
}
.sub{
    font-size: $text-regular;
    font-weight: 700;
    .card:hover &{
        color: var(--linkColor);
    }
}

.body{
    font-size: $text-regular;
}