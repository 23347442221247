@import-normalize;

html {
  --backgroundColor: #210c1c;
  --textColor: #dfdada;
  --linkColor: #d76061;
  --linkColorHover: #d7606144;
  --colorModeToggleBackground: rgba(0, 0, 0, 0.5);
  --cardColor: rgba(0, 0, 0, 0.3);
  --cardColorShine: rgba(0, 0, 0, 1);
  --cardShadowColor: #d8393a33;
  --codeBGColor: #0008;

  --sidePad: 20px;

  --accentShade: #b188a649;
}
html.theme--light {
  --backgroundColor: #dfdada;
  --textColor: #0a273a;
  --linkColor: #d8393a;
  --linkColorHover: #d8393a44;
  --colorModeToggleBackground: rgba(255, 255, 255, 0.5);
  --cardColor: #dfd5d5;
  --cardColorShine: rgba(255, 255, 255, .5);
  --cardShadowColor: #a7232399;
  --codeBGColor: #fff8;
}

@media screen and (max-width: 1200px) {
  html {
    --sidePad: 12px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  html{
    scroll-behavior: smooth;
  }
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  box-sizing: border-box;
  background-image: url(./images/dust-bg.png);
  background-attachment: fixed;
}
body {
  padding: 0;
  margin: 0;
}

code {
  font-family: "JetBrains Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace !important;
}

*, *:before, *:after {
  box-sizing: inherit;
}